import React from 'react'
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const Login = () => {
	const history = useNavigate();

	const Auth = async (e) => {
		e.preventDefault();
		try {
			await axios.delete('http://localhost:5000/logout');
			history.push("/dashboard");
		} catch (error) {

		}
	}
	return (< section className="hero has-background-grey-light is-fullheight is-fullwidth">
			< div className="hero-body">
				<div className="container">
					<div className="columns is-centered">
						<div className="column is-4-desktop">
							<form onSubmit={Auth} className="box">
								<div className="field mt-5">
									<button className="button is-success is-fullwidth"> Logout< /button>
								</div>
							</form>
						< /div>
					</div>
				< /div>
			</div>
		< /section>
	)
}

export default Login