import React, {useEffect, useState} from "react";
import "../assets/sticker.css";
import {StickerNavbar} from "./Stickernavbar";
import {Alert, Button} from "@material-tailwind/react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import moment from "moment";

export const Sticker = () => {

	const [showModal, setShowModal] = React.useState(false);
	const [showModalText, setShowModalText] = React.useState({state: false, note: ''});

	const [text, setText] = useState("");
	const [textValidation, setTextValidation] = useState("");
	const [responseErrorMessage, setResponseErrorMessage] = useState("");
	const [notes, setNotes] = useState([]);

	const history = useNavigate();
	const token = localStorage.getItem("access_token");

	const handleSubmit = async () => {

		if (text === '') {
			setTextValidation('The Text can not be empty');
			return;
		}
		try {
			const res = await axios.post("http://localhost:3001/auth/api/insert-note", {
				token: token, text: text
			});
			if (res.data === 'empty') {
				setResponseErrorMessage("these credentials are invalid");
				return;
			} else {
				console.log(res.data);
				// setResponseErrorMessage("");
				// localStorage.setItem("access_token", res.data);
				// history("/");
			}
		} catch (error) {
			setResponseErrorMessage('No Connection with th Server');
		}
		setTextValidation('');
		setText('');

		setShowModal(false);

	}

	useEffect(() => {
		let token = localStorage.getItem("access_token");

		const res = axios.post("http://localhost:3001/auth/api/get-notes", {
			token: token,
		}).then(function (response) {
			setNotes(response.data);
		});
	}, [handleSubmit]);

	return (
		<>
			<StickerNavbar/>
			<Button style={{color: 'white', backgroundColor: 'rgba(147,124,50,0.94)', margin: '5px'}}
			        onClick={() => setShowModal(true)}>Plus</Button>

			<div className="grid sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-12 xl:grid-cols-12">
				{notes.map((note, key) =>
					<div className="small card" key={key} onClick={() => setShowModalText({state: true, note: note })}>
						<header className="title_1">
							{moment(note.created_at).format("YYYY-MM-DD HH:mm:ss")}
						</header>
						<div className="content" style={{overflow: 'hidden'}}>
							<strong> {note.body.slice(0, 30)} </strong>
						</div>
					</div>
				)}
			</div>

			{showModal ? (
				<>
					<div
						className="flex justify-center items-center  sdoverflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none " style={{backgroundColor: '#cecece'}}>
						<div
							className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
							<div
								className="flex items-start justify-between p-1 border-b border-solid border-slate-200 rounded-t">
								<h2 className="text-3xl font-semibold" style={{margin: '10px', padding: '10px'}}>Write
									your small story about this minute </h2>
								<button style={{color: 'black', margin: '15px'}} onClick={() => setShowModal(false)}>X
								</button>
							</div>
							{textValidation && <Alert style={{
								color: 'white',
								backgroundColor: 'rgba(115,38,38,0.91)'
							}}>{textValidation}</Alert>}

							<div className=" h-full">
				<textarea rows="22" cols="33" style={{
					fontFamily: "Arial;font-size: 12pt",
					width: "90%",
					height: "90%",
					padding: '10px',
					margin: '20px'
				}}
				          className="shadow appearance-none border rounded  py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				          id="username" type="text" onChange={(e) => setText(e.target.value)}/>
							</div>
							<div>
								<Button style={{color: 'white', backgroundColor: '#7c3232', margin: '5px'}}
								        onClick={() => setShowModal(false)}>Close</Button>
								<Button style={{color: 'white', backgroundColor: '#12564d', margin: '5px'}}
								        onClick={() => handleSubmit()}>Save</Button>

							</div>
						</div>
					</div>
				</>
			) : null}


			{showModalText.state ? (
				<>
					<div
						className="flex justify-center items-center  sdoverflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none" style={{backgroundColor: '#737373'}}>
						<div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none" style={{width: '90%', height: '50%'}}>
							<div
								className="flex items-start justify-between p-1 border-b border-solid border-slate-200 rounded-t">
								<h2 className="text-3xl font-semibold" style={{margin: '10px', padding: '10px'}}>Note (1) </h2>
								<button style={{color: 'black', margin: '15px'}}
								        onClick={() => setShowModalText({state: false, note: ''})}>X
								</button>
							</div>

							<div className=" h-full" style={{width: '100%',overflow: 'scroll', minHeight: '50%'}}>
								<div className="">
								{showModalText.note.body}
								</div>

							</div>
							<div>
								<p>{moment(showModalText.note.created_at).format("YYYY-MM-DD HH:mm:ss")}</p>
							</div>
							<div>
								<Button style={{color: 'white', backgroundColor: '#7c3232', margin: '5px'}}
								        onClick={() => setShowModalText({state: false, note: ''})}>Close</Button>
							</div>
						</div>
					</div>
				</>
			) : null}


		</>
	);
};
