import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import bcrypt from 'bcryptjs'

const Register = () => {
	const salt = bcrypt.genSaltSync(10)

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confPassword, setConfPassword] = useState("");
	const [passwordError, setPasswordErr] = useState("");
	const [accountIsExists, setAccountIsExists] = useState("");

	const [inputFildsEmpty, setInputFildsEmpty] = useState("");
	const [uppercasePasswordCheck, setUppercasePasswordCheck] = useState("");

	const [emailCheckervalidation, setEmailCheckervalidation] = useState("");

	const [lowercasePasswordChecker, setLowercasePasswordChecker] = useState("");
	const [digitsPasswordChecker, setDigitsPasswordChecker] = useState("");
	const [specialCharPasswordChecker, setSpecialCharPasswordChecker] =
		useState("");
	const [minLengthPasswordChecker, setMinLengthPasswordChecker] = useState("");
	const [passowrdEqualConfPassword, setPassowrdEqualConfPassword] =
		useState("");
	const [passAllValidation, setPassAllValidation] = useState(false);

	const history = useNavigate();

	const Register = async (e) => {
		e.preventDefault();

		const uppercaseRegExp = /(?=.*?[A-Z])/;
		const regexEmail = /\S+@\S+\.\S+/;
		const lowercaseRegExp = /(?=.*?[a-z])/;
		const digitsRegExp = /(?=.*?[0-9])/;
		const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
		const minLengthRegExp = /.{8,}/;

		const nameLength = name.length;
		const confPasswordLength = password.length;
		const passwordLength = password.length;
		const emailLength = email.length;

		const uppercasePassword = uppercaseRegExp.test(password);
		const emailChecker = regexEmail.test(email);

		const lowercasePassword = lowercaseRegExp.test(password);
		const digitsPassword = digitsRegExp.test(password);
		const specialCharPassword = specialCharRegExp.test(password);
		const minLengthPassword = minLengthRegExp.test(password);

		let checker1 = false;
		let checker2 = false;
		let checker3 = false;
		let checker4 = false;
		let checker5 = false;
		let checker6 = false;
		let checker7 = false;
		let checker8 = false;

		if (
			passwordLength == 0 ||
			nameLength == 0 ||
			confPasswordLength == 0 ||
			emailLength == 0
		) {
			setInputFildsEmpty("The Imput Fileds can not be empty");
		} else {
			setInputFildsEmpty("");
			checker1 = true;
		}
		if (!emailChecker) {
			setEmailCheckervalidation("Enter a valid E-Mail-Adress");
		} else {
			setEmailCheckervalidation("");
			checker3 = true;
		}
		if (!uppercasePassword) {
			setUppercasePasswordCheck(
				"The password should be At least one Uppercase"
			);
		} else {
			setUppercasePasswordCheck("");
			checker2 = true;
		}
		if (!lowercasePassword) {
			setLowercasePasswordChecker(
				" The password should be At least one Lowercase"
			);
		} else {
			setLowercasePasswordChecker("");
			checker4 = true;
		}
		if (!digitsPassword) {
			setDigitsPasswordChecker("The password should be At least one digit");
		} else {
			setDigitsPasswordChecker("");
			checker5 = true;
		}
		if (!specialCharPassword) {
			setSpecialCharPasswordChecker(
				"The password should be At least one Special Characters"
			);
		} else {
			setSpecialCharPasswordChecker("");
			checker6 = true;
		}
		if (!minLengthPassword) {
			setMinLengthPasswordChecker(
				"The password should be At least minumum 8 characters"
			);
		} else {
			setMinLengthPasswordChecker("");
			checker7 = true;
		}
		if (password != confPassword) {
			setPassowrdEqualConfPassword("The Password can not match confPassword");
		} else {
			setPassowrdEqualConfPassword("");
			checker8 = true;
		}

		if (
			checker1 != false &&
			checker2 != false &&
			checker3 != false &&
			checker4 != false &&
			checker5 != false &&
			checker6 != false &&
			checker7 != false &&
			checker8 != false
		) {
			try {
				const hashedPassword = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u') // hash created previously created upon sign up
				await axios
					.post("http://localhost:3001/auth/api/register", {
						name: name,
						email: email,
						password: hashedPassword,
					})
					.then((response) => {
						if (response.data === 'exists'){
							setAccountIsExists('Account with this E-Mail is exists');
							return;
						}else{
							localStorage.setItem("access_token", response.data);
							history("/");
						}

					});
				// history("/login");
			} catch (err) {
				console.log(err);
			}
		}
	};

	return (
		<section className="hero has-background-grey-light is-fullheight is-fullwidth">
			<div className="hero-body">
				<div className="container">
					< div className="columns is-centered">
						<div className="column is-4-desktop">
							<div style={{color: "red"}}>
								<p> {accountIsExists} < /p>
								<p> {inputFildsEmpty} < /p> <p> {emailCheckervalidation} </p>
								<p> {uppercasePasswordCheck} < /p> < p> {lowercasePasswordChecker} </p>
								<p> {digitsPasswordChecker} < /p> <p> {specialCharPasswordChecker} </p>
								<p> {minLengthPasswordChecker} < /p> <p> {passowrdEqualConfPassword} </p>
							</div>
							<form onSubmit={Register} className="box">
								<p className="text-danger"> {passwordError} < /p>
								< div className="field mt-5">
									< label className="label"> Name </label>
									< div className="controls">
										< input type="text"
										        className="input"
										        placeholder="Name"
										        value={name}
										        onChange={
											        (e) => setName(e.target.value)}/>
									</div>
								</div>
								< div className="field mt-5"><label className="label"> Email < /label>

									<input type="text"
									       className="input"
									       placeholder="Email"
									       value={email}
									       onChange={
										       (e) => setEmail(e.target.value)}/>
								</div>
								< div className="field mt-5">
									<label className="label"> Password < /label>
									< div className="controls">
										<input type="password"
										       className="input"
										       placeholder="******"
										       value={password}
										       onChange={(e) => setPassword(e.target.value)}/></div>
								</div>
								< div className="field mt-5">
									<label className="label"> Confirm Password < /label>
									<div className="controls">
										<input type="password"
										       className="input"
										       placeholder="******" value={confPassword}
										       onChange={(e) => setConfPassword(e.target.value)}/></div>
								</div>
								<div className="field mt-5">
									<button className="button is-success is-fullwidth">
										Register
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Register;