import React from "react";

import {Link, useNavigate} from "react-router-dom";

export const StickerNavbar = () => {
	const history = useNavigate();

	const logout = (e) => {
		history("/login");
		window.localStorage.clear();
	};

	return (<>
			<nav className="bg-white shadow dark:bg-gray-800">
				< div
					className="container flex items-center justify-center p-6 mx-auto text-gray-600 capitalize dark:text-gray-300">
					<Link
						className="text-gray-800 transition-colors duration-200 transform dark:text-gray-200 border-b-2 border-blue-500 mx-1.5 sm:mx-6"
						to="/">Home </Link>
					<button onClick={() => logout()}
					        className="border-b-2 border-transparent hover:text-gray-800 transition-colors duration-200 transform dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6">
						Logout
					</button>
				< /div>
			< /nav>
		</>
	);
};