/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import {Link, useNavigate} from "react-router-dom";
import {Sticker} from "./layout/Sticker";
import Login from "./Login.js";

const Dashboard = () => {
	const history = useNavigate();

	const [authStatus, setAuthStatus] = useState(false);


	useEffect(() => {
		main();
	}, []);

	const main = () => {
		try {
			let token = localStorage.getItem("access_token");
			const decoded = jwt_decode(token);
			let currentDate = new Date();

			if (decoded.exp * 1000 > currentDate.getTime()) {
				setAuthStatus(true);
			} else {
				setAuthStatus(false);
				localStorage.clear("access_token");
			}
		} catch (error) {
			history('/')
		}
	}

	if (authStatus === true) {
		return <Sticker/>;
	}
	return <Login/>;
};

export default Dashboard;