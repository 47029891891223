import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./componanten/Dashboard";
import Login from "./componanten/Login";
import Register from "./componanten/Register";
import Logout from "./componanten/Logout";
import axios from "axios";

import { SimpleFolio } from "./componanten/layout/SimpleFolio.js";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<SimpleFolio />} />{" "}
        <Route exact path="/dashboard" element={<Dashboard />} />{" "}
        <Route exact path="/login" element= {<Login />} />{" "}
        <Route exact path="/register" element= {<Register />} />{" "}
        <Route exact path="/logout" element= {<Logout />} />{" "}
        <Route path="*" element= {<Login />} />{" "}
      </Routes>{" "}
    </BrowserRouter>
  );
}

export default App;
