import React, {useState} from "react";
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const Navbar = () => {
	const history = useNavigate();
	const [email, setEmail] = useState("");

	const Logout = async () => {
		try {
			await axios.delete('http://localhost:5000/logout');
			history.push("/");
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<h1>Hallo in Note App</h1>
	)
}

export default Navbar