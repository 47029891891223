import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Navbar from "./Navbar";
import bcrypt from 'bcryptjs'

const salt = bcrypt.genSaltSync(10)

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [responseErrorMessage, setResponseErrorMessage] = useState("");

	const [inputFildsEmpty, setInputFildsEmpty] = useState("");
	const [emailCheckervalidation, setEmailCheckervalidation] = useState("");
	const [msg, setMsg] = useState("");

	const history = useNavigate();

	const Auth = async (e) => {
		e.preventDefault();


		const regexEmail = /\S+@\S+\.\S+/;

		const emailLength = email.length;
		const passpordLength = password.length;

		const emailChecker = regexEmail.test(email);

		let checker1 = false;
		let checker2 = false;

		if (emailLength === 0 || passpordLength === 0) {
			setInputFildsEmpty("The Input Fields can not be empty");
		} else {
			setInputFildsEmpty("");
			checker1 = true;
		}
		if (!emailChecker) {
			setEmailCheckervalidation("Enter a valid E-Mail-Address");
		} else {
			setEmailCheckervalidation("");
			checker2 = true;
		}
		if (checker1 !== false && checker2 !== false) {
			const hashedPassword = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u') // hash created previously created upon sign up

			try {
				const res = await axios.post("http://localhost:3001/auth/api/login", {
					email: email, password: hashedPassword
				});
				if (res.data === 'empty') {
					setResponseErrorMessage("these credentials are invalid");
					return;
				} else {
					setResponseErrorMessage("");
					localStorage.setItem("access_token", res.data);
					history("/");
				}
			} catch (error) {
					setResponseErrorMessage('No Connection with th Server');
			}
		}
	};

	return (<>
			< Navbar/>
			<section className="hero has-background-grey-light is-fullheight is-fullwidth">
				< div className="hero-body">
					<div className="container">
						<div className="columns is-centered">
							<div className="column is-4-desktop">
								<div style={{color: "red"}}>
									<p> {emailCheckervalidation} < /p> <
									p> {inputFildsEmpty} < /p> <
									p> {responseErrorMessage} < /p></div>
								<form onSubmit={Auth}
								      className="box">
									<p className="has-text-centered"> {msg} < /p>
									<div className="field mt-5">
										<label className="label"> Email or Username </label>
										<div className="controls">
											<input type="text"
											       className="input"
											       placeholder="Username"
											       value={email}
											       onChange={(e) => setEmail(e.target.value)}/></div>
									</div>
									<div className="field mt-5">
										<label className="label"> Password < /label>
										<div className="controls">
											<input type="password"
											       className="input"
											       placeholder="******"
											       value={password}
											       onChange={(e) => setPassword(e.target.value)}/></div>
									</div>
									<div className="field mt-5">
										<button className="button is-success is-fullwidth">
											Login
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				< /div>
			</section>
		</>
	);
};
export default Login;